import Head from 'next/head';
import { AuthenticationLayout } from '@Components/AppLayout';
import { SignInController } from '@Views';
import { useSetHotjar } from '@Hooks/useSetHotjar';

const SignInPage = () => {
  useSetHotjar();

  return (
    <AuthenticationLayout>
      <Head>
        <title>{`Sign-in | ActionFunder`}</title>
      </Head>
      <SignInController />
    </AuthenticationLayout>
  );
};

export default SignInPage;
