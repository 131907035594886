import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

export function useSetHotjar() {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_HOTJAR_ID) {
      hotjar.initialize(parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID), 6);
    }
  }, []);
}
